export const AVAILABLE_LANGUAGES = [
  'en',
  'zh-HK',
  'zh-CN',
  'id',
  'pt-BR',
  'de',
  'it',
] as const;

export type AvailableLanguage = typeof AVAILABLE_LANGUAGES[number];
